import ProfilePageConfig from './profile/ProfilePageConfig';
import ForgotPasswordConfig from '../forgot-password/ForgotPasswordConfig';
import DashboardConfig from "./admin/dashboards/DashboardConfig";
import SubscriptionConfig from "./subscription/SubscriptionConfig";
import InvoiceConfig from "./invoice/InvoiceConfig";
import ContractConfig from "./contract/ContractConfig";
import ConditionConfig from "./condition/ConditionConfig";
import PolicyConfig from "./policy/PolicyConfig";
import OffersPageConfig from "./offers/OffersPageConfig";
import TestsPageConfig from "./tests/TestsPageConfig";
import TrainingPageConfig from "./training/TrainingPageConfig";
import Error404PageConfig from "./errors/404/Error404PageConfig";
import Error500PageConfig from "./errors/500/Error500PageConfig";
import CandidatePageConfig from "./admin/candidate/CandidatePageConfig";
import CompanyPageConfig from "./admin/company/CompanyPageConfig";
import TrainingAdminPageConfig from "./admin/training/TrainingAdminPageConfig";
import TestsAdminPageConfig from "./admin/tests/TestsAdminPageConfig";
import OfferAdminPageConfig from "./admin/offers/OfferAdminPageConfig";
import MemberAdminPageConfig from "./admin/member/MemberAdminPageConfig";
import ConfigurationPageConfig from "./admin/setting/configuration/ConfigurationPageConfig";
import UserPageConfig from "./admin/setting/users/UserPageConfig";
import ChangePasswordConfig from "../change-password/ChangePasswordConfig";
import ConfirmContractConfig from "../confirm-contract/ConfirmContractConfig";
import MailConfirmPageConfig from "../mail-confirmation/MailConfirmPageConfig";
import LoginConfig from "../login/LoginConfig";
import ChangepasswordprofileConfig from "./changePasswordProfile/changepasswordprofileConfig";
import ProfileAdminPageConfig from "./admin/profile/ProfilePageConfig";
import EvaluationPageConfig from "./admin/evaluate/EvaluationPageConfig";
import PaymentPageConfig from "../payment/PaymentPageConfig";
import SupportPageConfig from "./support/SupportPageConfig";
import EmployeesAdminPageConfig from "./admin/employees/EmployeesPageConfig";

const pagesConfigs = [
	ConfirmContractConfig,
	EmployeesAdminPageConfig,
	MailConfirmPageConfig,
	LoginConfig,
	ChangePasswordConfig,
	ProfilePageConfig,
	ForgotPasswordConfig,
	InvoiceConfig,
	ContractConfig,
	ConditionConfig,
	OffersPageConfig,
	ChangepasswordprofileConfig,
	ProfileAdminPageConfig,
	TestsPageConfig,
	TrainingPageConfig,
	PolicyConfig,
	SubscriptionConfig,
	Error404PageConfig,
	Error500PageConfig,
	CandidatePageConfig,
	CompanyPageConfig,
	TrainingAdminPageConfig,
	TestsAdminPageConfig,
	EvaluationPageConfig,
	OfferAdminPageConfig,
	MemberAdminPageConfig,
	ConfigurationPageConfig,
	PaymentPageConfig,
	SupportPageConfig,
	UserPageConfig,
	DashboardConfig
];

export default pagesConfigs;
