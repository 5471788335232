import FuseUtils from '@fuse/utils/FuseUtils';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import {useHistory} from "react-router";
/* eslint-disable camelcase */

class JwtService extends FuseUtils.EventEmitter {
	init() {
		this.setInterceptors();
		this.handleAuthentication();
	}


	setInterceptors = () => {
		axios.interceptors.response.use(
			response => {
				return response;
			},
			err => {
				return new Promise((resolve, reject) => {
					if (err && err.response && err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
						// if you ever get an unauthorized response, logout the user
						if(err.response.data.error != null){
							this.emit('onAutoLogout', err.response.data.error);
						}else{
							this.emit('onAutoLogout', err.response.data.message);
						}
						//this.emit('onAutoLogout', err.response.data.error);
						this.setSession(null);
					}

					if (err && err.response && err.response.status === 500 && err.config && !err.config.__isRetryRequest) {
						// if you ever get an unauthorized response, logout the user
						if(err.response.data && err.response.data.type === 'ExpiredJwtException'){
							if(err.response.data.error != null){
								this.emit('onAutoLogout', err.response.data.error);
							}else{
								this.emit('onAutoLogout', err.response.data.message);
							}
							//this.emit('onAutoLogout', "Veillez vous reconnecter");
							this.setSession(null);
						}
					}

					if (err && err.response && err.response.status === 400 && err.config && !err.config.__isRetryRequest) {
						// if you ever get an unauthorized response, logout the user
						if(err.response.data.error != null){
							this.emit('onAutoChange', err.response.data.error);
						}else{
							this.emit('onAutoChange', err.response.data.message);
						}
						//this.emit('onAutoLogout', err.response.data.error);
						//this.setSession(null);
					}

					if (err && err.response && err.response.status === 404 && err.config && !err.config.__isRetryRequest) {
						// if you ever get an unauthorized response, logout the user
						if(err.response.data.error != null){
							this.emit('onAutoChange', err.response.data.error);
						}else{
							this.emit('onAutoChange', err.response.data.message);
						}

						//this.emit('onAutoLogout', err.response.data.error);
						//this.setSession(null);
					}

					if (err && err.response && err.response.status === 402 && err.config && !err.config.__isRetryRequest) {
						// if you ever get an unauthorized response, logout the user
						if(err.response.data.errors != null){
							this.emit('onAutoChange', err.response.data.errors);
						}else{
							this.emit('onAutoChange', err.response.data.errors);
						}

						//this.emit('onAutoLogout', err.response.data.error);
						//this.setSession(null);
					}
					throw err;
				});
			}
		);
	};

	handleAuthentication = () => {
		const access_token = this.getAccessToken();

		if (!access_token) {
			this.emit('onNoAccessToken');

			return;
		}

		if (this.isAuthTokenValid(access_token)) {
			this.setSession(access_token);
			this.emit('onAutoLogin', true);
		} else {
			this.setSession(null);
			this.emit('onAutoLogout', 'access_token expired');
		}
	};

	createUser = data => {
		return new Promise((resolve, reject) => {
			axios.post('/api/auth/register', data).then(response => {
				if (response.data.user) {
					this.setSession(response.data.access_token);
					resolve(response.data.user);
				} else {
					reject(response.data.error);
				}
			});
		});
	};

	signInWithEmailAndPassword = (email, passwordw) => {
		return new Promise((resolve, reject) => {
			axios
				.post(`${process.env.REACT_APP_API_URL}/api/v1.0/auth/sign-in`, {
					login: email,
					password: passwordw
				})
				.then(response => {
					if (response.data.userId) {
						this.setSession(response.data.bearerToken);
						resolve(response.data.userId);
					} else {
						reject(response.data.error);
					}
				});
		});
	};

	ChangePassword = (oldPasswordp, passwordp, id) => {
		return new Promise((resolve, reject) => {
			axios
				.put(`${process.env.REACT_APP_API_URL}/api/v1.0/auth/user/${id}/password-update`, {
					oldPassword: oldPasswordp,
					password: passwordp
				})
				.then(response => {
					if (response.data.status_code === 'OK') {
						this.emit('onAutoLogout', 'Mot de passe changé avec succèss');
					} else {
						reject(response.data.message);
					}
				});
		});
	};

	ResetPasswordConfirm = (passwordp, code) => {
		return new Promise((resolve, reject) => {
			axios
				.put(`${process.env.REACT_APP_API_URL}/api/v1.0/auth/reset-password?code=${code}`, {
					password: passwordp
				})
				.then(response => {
					if (response.data.status_code === 'OK') {
						resolve(response.data);
					} else {
						reject(response.data.message);
					}
				});
		});
	};

		ResetPassword = (email) => {
		return new Promise((resolve, reject) => {
			axios
				.post(`${process.env.REACT_APP_API_URL}/api/v1.0/auth/reset-password`, {
					login: email
				})
				.then(response => {
					if (response.data.status) {

						resolve(response.data);
						//this.emit('onAutoLogout', 'Mot de passe changé avec succèss');
					} else {
						reject(response.data.message);
					}
				});
		});
	};

	validContract = (id) => {
		return new Promise((resolve, reject) => {
			axios
				.post(`${process.env.REACT_APP_API_URL}/api/v1.0/particulars/signatureContract/${id}`, {
					status: true
				})
				.then(response => {
					if (response.data.status) {
						this.emit('onAutoLogout', 'Contrat validé avec succèss');
					} else {
						reject(response.data.message);
					}
				});
		});
	};

	getUser = () => {
		return new Promise((resolve, reject) => {
			axios
				.get(`${process.env.REACT_APP_API_URL}/api/v1.0/auth/user/me`)
				.then(response => {
					if (response.data.userId && response.data.roles.length > 0) {
						this.setNotification(response.data.countOfferJob ,response.data.countTest);
						response.data.role = response.data.roles[0].name;
						resolve(response.data);
					} else {
						reject(response.data.error);
					}
				});
		});
	};

	signInWithToken = () => {
		return new Promise((resolve, reject) => {
			axios
				.get(`${process.env.REACT_APP_API_URL}/api/v1.0/auth/user/me`, {
					data: {
						access_token: this.getAccessToken()
					}
				})
				.then(response => {
					if (response.data.userId) {
						//this.setSession(response.data.access_token);
						response.data.role = response.data.roles[0].name;
						resolve(response.data);
					} else {
						this.logout();
						reject(new Error('Failed to login with token.'));
					}
				})
				.catch(error => {
					this.logout();
					reject(new Error('Failed to login with token.'));
				});
		});
	};

	updateUserData = user => {
		return axios.post('/api/auth/user/update', {
			user
		});
	};

	setSession = access_token => {
		if (access_token) {
			localStorage.setItem('jwt_access_token', access_token);
			axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
		} else {
			localStorage.removeItem('jwt_access_token');
			delete axios.defaults.headers.common.Authorization;
		}
	};


	setNotification = (offer, evaluation) => {
		localStorage.setItem('notification_offer', offer);
		localStorage.setItem('notification_evaluation', evaluation);
	};

	logout = () => {
		this.setSession(null);
	};

	isAuthTokenValid = access_token => {
		if (!access_token) {
			return false;
		}
		const decoded = jwtDecode(access_token);
		const currentTime = Date.now() / 1000;
		if (decoded.exp < currentTime) {
			console.warn('access token expired');
			return false;
		}

		return true;
	};

	getAccessToken = () => {
		return window.localStorage.getItem('jwt_access_token');
	};
}

const instance = new JwtService();

export default instance;
