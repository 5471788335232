import { authRoles } from 'app/auth';
import ConfirmContract from './ConfirmContract';

const ConfirmContractConfig = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: false
				},
				toolbar: {
					display: false
				},
				footer: {
					display: false
				},
				leftSidePanel: {
					display: false
				},
				rightSidePanel: {
					display: false
				}
			}
		}
	},
	auth: authRoles.user,
	routes: [
		{
			path: '/confirm-contract',
			component: ConfirmContract
		}
	]
};

export default ConfirmContractConfig;
