import { lazy } from 'react';
import {authRoles} from "../../../../auth";

const DashboardConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.admin,
	routes: [
		{
			path: '/',
			component: lazy(() => import('./Dashboard'))
		}
	]
};

export default DashboardConfig;
