import { lazy } from 'react';
import {authRoles} from "../../../../auth";

const MemberAdminPageConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth: authRoles.admin,
    routes: [
        {
            path: '/admin/member/add',
            component: lazy(() => import('./MemberAdd'))
        },
        {
            path: '/admin/member/:id',
            component: lazy(() => import('./MemberList'))
        },
        {
            path: '/admin/member/list',
            component: lazy(() => import('./MemberList'))
        }
    ]
};

export default MemberAdminPageConfig;
