const locale = {
	APPLICATIONS: 'Applications',
	DASHBOARDS: 'Dashboards',
	OFFERS: 'Offres d\'emploi',
	LIST: 'List',
	ADD: 'Add',
	COMPANY: 'Company',
	CANDIDATE: 'Candidate',
	MEMBER: 'Member',
	EVALUATION: "Évaluation",
	EVALUATIONFRENCH: "Évaluation linguistique de français",
	EVALUATIONENGLISH: "Évaluation linguistique d'anglais",
	CALENDAR: 'Calendar',
	ECOMMERCE: 'E-Commerce',
	ACADEMY: 'Academy',
	MAIL: 'Mail',
	TODO: 'To-Do',
	FILE_MANAGER: 'File Manager',
	CONTACTS: 'Contacts',
	CHAT: 'Chat',
	SCRUMBOARD: 'Scrumboard',
	NOTES: 'Notes'
};

export default locale;
