import { lazy } from 'react';
import {authRoles} from "../../../auth";

const SubscriptionConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth: authRoles.user,
    routes: [
        {
            path: '/subscription',
            component: lazy(() => import('./Subscription'))
        }
    ]
};

export default SubscriptionConfig;
