import { lazy } from 'react';
import {authRoles} from "../../../auth";

const SupportPageConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth: authRoles.user,
    routes: [
        {
            path: '/support',
            component: lazy(() => import('./SupportAdd'))
        }
    ]
};

export default SupportPageConfig;
