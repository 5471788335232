import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@material-ui/core/Button';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {changePasseword, submitLogin, validContract} from 'app/auth/store/loginSlice';
import * as yup from 'yup';

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({

});

const defaultValues = {
};

function JWTConfirmContractTab(props) {
	const dispatch = useDispatch();
	const login = useSelector(({ auth }) => auth.login);
	const user = useSelector(({ auth }) => auth.user);
	const { control, setValue, formState, handleSubmit, reset, trigger, setError } = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema)
	});

	const today = Date.now();

	const form = new Intl.DateTimeFormat('fr-FR', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(today);

	function onSubmit(model) {
		const dataChange = {
			id: user.userId
		}
		dispatch(validContract(dataChange));
	}

	return (
		<div className="w-full">
			<form className="flex flex-col justify-center w-full" onSubmit={handleSubmit(onSubmit)}>
				<div>
					<p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: '16px', fontFamily: '"Arial",sans-serif', textAlign: 'center'}}><strong><span style={{fontSize: '13px', lineHeight: '115%'}}>CONTRAT DE SERVICES ENTRE JOB.ETROUVE ET SOUS-TRAITANT MEMBRE</span></strong></p>
					<p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: '16px', fontFamily: '"Arial",sans-serif', textAlign: 'center'}}><strong><span style={{fontSize: '13px', lineHeight: '115%'}}>&nbsp;</span></strong></p>
					<p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: '16px', fontFamily: '"Arial",sans-serif', textAlign: 'justify'}}><strong><span style={{fontSize: '13px', lineHeight: '115%'}}>INVESTISSEMENTS WOULIB INC.</span></strong><span style={{fontSize: '13px', lineHeight: '115%'}}>, personne morale de droit privée, constituée en vertu de la Loi canadienne sur les sociétés par actions (L.R.C. (1985), c. C-44), faisant affaire sous le nom «&nbsp;Job.Etrouve&nbsp;», ayant sa principale place d’affaire au 202-815 rue King Ouest, dans la Ville de Sherbrooke, province de Québec, Canada, J1H 1R9 ;</span></p>
					<p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: '16px', fontFamily: '"Arial",sans-serif', textAlign: 'justify'}}><span style={{fontSize: '13px', lineHeight: '115%'}}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;ci-après «&nbsp;Job.Etrouve&nbsp;»</span></p>
					<p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: '16px', fontFamily: '"Arial",sans-serif', textAlign: 'justify'}}><span style={{fontSize: '13px', lineHeight: '115%'}}>ET</span></p>
					<p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: '16px', fontFamily: '"Arial",sans-serif', textAlign: 'justify'}}><span style={{fontSize: '13px', lineHeight: '115%'}}><strong>{user.firstname} {user.lastname}</strong>, {user.town} {user.city} {user.country.nameFr}</span></p>
					<p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: '16px', fontFamily: '"Arial",sans-serif', textAlign: 'justify'}}><span style={{fontSize: '13px', lineHeight: '115%'}}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;ci-après « &nbsp;SOUS-TRAITANT MEMBRE »</span></p>
					<p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: '16px', fontFamily: '"Arial",sans-serif', textAlign: 'justify'}}><strong><span style={{fontSize: '13px', lineHeight: '115%'}}>&nbsp;</span></strong></p>
					<p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: '16px', fontFamily: '"Arial",sans-serif', textAlign: 'justify'}}><strong><span style={{fontSize: '13px', lineHeight: '115%'}}>PRÉAMBULE</span></strong></p>
					<p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '4.0cm', lineHeight: '115%', fontSize: '16px', fontFamily: '"Arial",sans-serif', textAlign: 'justify', textIndent: '-4.0cm'}}><strong><span style={{fontSize: '13px', lineHeight: '115%'}}>&nbsp;</span></strong></p>
					<p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '4.0cm', lineHeight: '115%', fontSize: '16px', fontFamily: '"Arial",sans-serif', textAlign: 'justify', textIndent: '-4.0cm'}}><strong><span style={{fontSize: '13px', lineHeight: '115%'}}>ATTENDU QUE&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></strong><span style={{fontSize: '13px', lineHeight: '115%'}}>Job.Etrouve agît comme intermédiaire entre des entreprises situées au Canada qui cherchent à combler leurs besoins en main d’œuvre dans un contexte de pénurie de main d’œuvre, et des personnes physiques qui n’habitent pas au Québec mais qui pourraient avoir les qualifications recherchées par une entreprise du Canada ;</span></p>
					<p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '4.0cm', lineHeight: '115%', fontSize: '16px', fontFamily: '"Arial",sans-serif', textAlign: 'justify', textIndent: '-4.0cm'}}><strong><span style={{fontSize: '13px', lineHeight: '115%'}}>ATTENDU QUE&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></strong><span style={{fontSize: '13px', lineHeight: '115%'}}>la personne proposée possède les qualifications recherchées, selon le profil soumis par l’entreprise ;</span></p>
					<p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '4.0cm', lineHeight: '115%', fontSize: '16px', fontFamily: '"Arial",sans-serif', textAlign: 'justify', textIndent: '-4.0cm'}}><strong><span style={{fontSize: '13px', lineHeight: '115%'}}>ATTENDU QUE&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></strong><span style={{fontSize: '13px', lineHeight: '115%'}}>la présente entente vise à mettre la personne proposée en relation directe avec une entreprise située au Canada aux fins de la conclusion d’un contrat de sous-traitance ;</span></p>
					<p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '4.0cm', lineHeight: '115%', fontSize: '16px', fontFamily: '"Arial",sans-serif', textAlign: 'justify', textIndent: '-4.0cm'}}><strong><span style={{fontSize: '13px', lineHeight: '115%'}}>ATTENDU QUE&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></strong><span style={{fontSize: '13px', lineHeight: '115%'}}>la personne doit être une personne physique et qu’elle doit au préalable être validée en passant certaines évaluations linguistiques et/ou évaluations psychométriques qui sont conçues et reconnues par les entreprises qui font affaires avec Job.Etrouve.</span></p>
					<p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: '16px', fontFamily: '"Arial",sans-serif', textAlign: 'justify'}}><strong><span style={{fontSize: '13px', lineHeight: '115%'}}>EN CONSÉQUENCE, LES PARTIES CONVIENNENT DE CE QUI SUIT, SAVOIR&nbsp;:</span></strong></p>
					<ol start={1} style={{marginBottom: '0cm', marginTop: '0cm'}} type={1}>
						<li style={{textAlign: 'justify'}}><span style={{fontSize: '13px', lineHeight: '115%'}}>Le préambule fait partie intégrante de la présente entente ;</span></li>
					</ol>
					<p style={{marginLeft: '36.0pt', textAlign: 'justify'}}><span style={{fontSize: '13px', lineHeight: '115%'}}>&nbsp;</span></p>
					<ol start={2} style={{marginBottom: '0cm', marginTop: '0cm'}} type={1}>
						<li style={{textAlign: 'justify'}}><span style={{fontSize: '13px', lineHeight: '115%'}}>Le Sous-Traitant Membre requiert les services de Job.Etrouve pour qu’elle le mette en contact avec une entreprise au Canada, en fonction de ses compétences et de la demande éventuelle pour les services qu’elle peut rendre, lesquels seront défini au fur et à mesure des besoins de l’entreprise ;</span></li>
						<li style={{textAlign: 'justify'}}><span style={{fontSize: '13px', lineHeight: '115%'}}>Le Sous-Traitant Membre effectuera le travail éventuel à partir du pays dans lequel {(user.sexe === 'M')? 'il': 'elle'}  vit et ne sera pas considéré comme un travailleur au sens des lois applicables au Canada mais plutôt comme un sous-traitant ;</span></li>
					</ol>
					<p><span style={{fontSize: '13px', lineHeight: '115%'}}>&nbsp;</span></p>
					<ol start={4} style={{marginBottom: '0cm', marginTop: '0cm'}} type={1}>
						<li style={{textAlign: 'justify'}}><span style={{fontSize: '13px', lineHeight: '115%'}}>Le présent contrat ne définit pas la relation contractuelle qui existera entre l’Entreprise Membre et la personne qui sera proposée, une fois la présente entente conclue et il leur appartient de la définir dans un document séparé ;</span></li>
					</ol>
					<p style={{marginLeft: '36.0pt'}}><span style={{fontSize: '13px', lineHeight: '115%'}}>&nbsp;</span></p>
					<ol start={5} style={{marginBottom: '0cm', marginTop: '0cm'}} type={1}>
						<li style={{textAlign: 'justify'}}><span style={{fontSize: '13px', lineHeight: '115%'}}>Le Sous-Traitant Membre ne sera, à aucun moment, un employé de Job.Etrouve au sens de la législation applicable au Québec, notamment le Code civil du Québec (RLRQ, c. C-1991) et la Loi sur les Normes du travail (RLRQ, c. N-1.1) ;</span></li>
					</ol>
					<p style={{marginLeft: '36.0pt'}}><span style={{fontSize: '13px', lineHeight: '115%'}}>&nbsp;</span></p>
					<ol start={6} style={{marginBottom: '0cm', marginTop: '0cm'}} type={1}>
						<li style={{textAlign: 'justify'}}><span style={{fontSize: '13px', lineHeight: '115%'}}>Aux fins de protéger les intérêts commerciaux de Job.Etrouve, si l’entreprise qui retient ses services requiert les services d’autres personnes, le Sous-Traitant Membre s’engage à ne pas solliciter d’autres personnes pour les proposer directement à l’entreprise ;</span></li>
					</ol>
					<p style={{marginLeft: '36.0pt'}}><span style={{fontSize: '13px', lineHeight: '115%'}}>&nbsp;</span></p>
					<ol start={7} style={{marginBottom: '0cm', marginTop: '0cm'}} type={1}>
						<li style={{textAlign: 'justify'}}><span style={{fontSize: '13px', lineHeight: '115%'}}>Si le Sous-Traitant Membre le désire toutefois, {(user.sexe === 'M')? 'il': 'elle'} peut, en tout temps, référer des gens qu’{(user.sexe === 'M')? 'il': 'elle'}  connait directement à Job.Etrouve pour que celle-ci tente de répondre aux besoins éventuels de cette personne ;</span></li>
					</ol>
					<p style={{marginLeft: '36.0pt'}}><span style={{fontSize: '13px', lineHeight: '115%'}}>&nbsp;</span></p>
					<ol start={8} style={{marginBottom: '0cm', marginTop: '0cm'}} type={1}>
						<li style={{textAlign: 'justify'}}><span style={{fontSize: '13px', lineHeight: '115%'}}>Le Sous-Traitant Membre reconnait que les termes des articles 6 et 7 du présent contrat sont des dispositions essentielles pour Job.Etrouve et que sans celles-ci, elle ne signerait pas le présent contrat ;</span></li>
					</ol>
					<p style={{marginLeft: '36.0pt'}}><span style={{fontSize: '13px', lineHeight: '115%'}}>&nbsp;</span></p>
					<ol start={9} style={{marginBottom: '0cm', marginTop: '0cm'}} type={1}>
						<li style={{textAlign: 'justify'}}><span style={{fontSize: '13px', lineHeight: '115%'}}>Le Sous-Traitant Membre reconnait également que {(user.sexe === 'M')? 'il': 'elle'} ne respectait pas les obligations contenues aux articles 6 et 7 du présent contrat, cela constituerait un bris de contrat et que Job.Etrouve pourrait alors exercer tous les recours juridiques à sa disposition au Québec pour mettre fin à la situation de bris de contrat et pour obtenir compensation pour tout dommage qu’elle pourrait subir d’un tel bris de contrat.</span></li>
					</ol>
					<p style={{marginLeft: '36.0pt'}}><span style={{fontSize: '13px', lineHeight: '115%'}}>&nbsp;</span></p>
					<ol start={10} style={{marginBottom: '0cm', marginTop: '0cm'}} type={1}>
						<li style={{textAlign: 'justify'}}><span style={{fontSize: '13px', lineHeight: '115%'}}>&nbsp;Job.Etrouve n’est pas responsable de la relation contractuelle qui sera établie par le Sous-Traitant Membre avec l’entreprise qui lui sera proposée et n’offre aucune garantie quant aux règles et obligations fiscales qui pourraient régir cette relation avec le sous-traitant membre étranger ;</span></li>
					</ol>
					<p style={{marginLeft: '36.0pt'}}><span style={{fontSize: '13px', lineHeight: '115%'}}>&nbsp;</span></p>
					<ol start={11} style={{marginBottom: '0cm', marginTop: '0cm'}} type={1}>
						<li style={{textAlign: 'justify'}}><span style={{fontSize: '13px', lineHeight: '115%'}}>&nbsp;Pour les fins des présentes, le Sous-Traitant Membre versera à Job.Etrouve la somme de 119,95$ (USD), à titre de frais d’adhésion ;</span></li>
					</ol>
					<p style={{textAlign: 'justify'}}><span style={{fontSize: '13px', lineHeight: '115%'}}>&nbsp;</span></p>
					<ol start={12} style={{marginBottom: '0cm', marginTop: '0cm'}} type={1}>
						<li style={{textAlign: 'justify'}}><span style={{fontSize: '13px', lineHeight: '115%'}}>Les frais mentionnés au paragraphe 11, ci-avant, sont payable annuellement et son sujet à indexation, à la discrétion de Job.Etrouve ;</span></li>
					</ol>
					<p style={{textAlign: 'justify'}}><span style={{fontSize: '13px', lineHeight: '115%'}}>&nbsp;</span></p>
					<ol start={13} style={{marginBottom: '0cm', marginTop: '0cm'}} type={1}>
						<li style={{textAlign: 'justify'}}><span style={{fontSize: '13px', lineHeight: '115%'}}>Les parties reconnaissent que la présente entente est confidentielle et elles s’engagent mutuellement, l’une envers l’autre, de ne pas en divulguer le contenu à des tiers, sauf si cela est nécessaire dans le cadre de leurs opérations normales, par exemple à leurs comptables ou conseillers juridiques ;</span></li>
					</ol>
					<p style={{marginLeft: '36.0pt'}}><span style={{fontSize: '13px', lineHeight: '115%'}}>&nbsp;</span></p>
					<ol start={14} style={{marginBottom: '0cm', marginTop: '0cm'}} type={1}>
						<li style={{textAlign: 'justify'}}><span style={{fontSize: '13px', lineHeight: '115%'}}>Le présent contrat contient la totalité de l’entente entre les parties et aucun autre document antérieur ne peut servir à en définir le contenu.</span></li>
					</ol>
					<p style={{marginLeft: '36.0pt'}}><span style={{fontSize: '13px', lineHeight: '115%'}}>&nbsp;</span></p>
					<ol start={15} style={{marginBottom: '0cm', marginTop: '0cm'}} type={1}>
						<li style={{textAlign: 'justify'}}><span style={{fontSize: '13px', lineHeight: '115%'}}>Aucune modification à ce contrat ne pourra être considérée à moins qu’elle ait été écrite, signée par chacune des parties et annexée à la présente entente.</span></li>
					</ol>
					<p style={{marginLeft: '36.0pt'}}><span style={{fontSize: '13px', lineHeight: '115%'}}>&nbsp;</span></p>
					<ol start={16} style={{marginBottom: '0cm', marginTop: '0cm'}} type={1}>
						<li style={{textAlign: 'justify'}}><span style={{fontSize: '13px', lineHeight: '115%'}}>Le présent contrat est régi par les lois applicables au Québec.</span></li>
					</ol>
					<p style={{marginLeft: '36.0pt'}}><span style={{fontSize: '13px', lineHeight: '115%'}}>&nbsp;</span></p>
					<ol start={17} style={{marginBottom: '0cm', marginTop: '0cm'}} type={1}>
						<li style={{textAlign: 'justify'}}><span style={{fontSize: '13px', lineHeight: '115%'}}>Tout différend ou litige découlant de la conclusion de ce contrat sera soumis au tribunal compétent dans le district judiciaire de Québec ;</span></li>
					</ol>
					<p style={{marginLeft: '36.0pt'}}><span style={{fontSize: '13px', lineHeight: '115%'}}>&nbsp;</span></p>
					<ol start={18} style={{marginBottom: '0cm', marginTop: '0cm'}} type={1}>
						<li style={{textAlign: 'justify'}}><span style={{fontSize: '13px', lineHeight: '115%'}}>La Partie ayant gain de cause se fera rembourser par l’autre Partie tous les coûts, dépenses et frais juridiques les frais de justice, tel qu’établis aux termes du Code de procédure civile du Québec (RLRQ, c. C-25.01) encourus pour l'exécution ou pour faire respecter l'exécution des dispositions, conditions et obligations du présent contrat.</span></li>
					</ol>
					<p style={{marginLeft: '36.0pt'}}><span style={{fontSize: '13px', lineHeight: '115%'}}>&nbsp;</span></p>
					<ol start={19} style={{marginBottom: '0cm', marginTop: '0cm'}} type={1}>
						<li style={{textAlign: 'justify'}}><span style={{fontSize: '13px', lineHeight: '115%'}}>Le fait qu’une disposition de ce contrat soit déclaré invalide par un tribunal n’invalide pas pour autant l’ensemble du contrat ;</span></li>
					</ol>
					<p style={{marginLeft: '36.0pt'}}><span style={{fontSize: '13px', lineHeight: '115%'}}>&nbsp;</span></p>
					<p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '35.45pt', lineHeight: '115%', fontSize: '16px', fontFamily: '"Arial",sans-serif', textAlign: 'justify', textIndent: '-21.25pt'}}><span style={{fontSize: '13px', lineHeight: '115%'}}>EN FOI DE QUOI LES PARTIES ONT SIGNÉ, CE {form}.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></p>
					<p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '35.45pt', lineHeight: '115%', fontSize: '16px', fontFamily: '"Arial",sans-serif', textAlign: 'justify', textIndent: '-21.25pt'}}><span style={{fontSize: '13px', lineHeight: '115%'}}>&nbsp;</span></p>
					<p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: '16px', fontFamily: '"Arial",sans-serif'}}><span style={{fontSize: '13px', lineHeight: '115%'}}>________________________________________</span></p>
					<p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '35.45pt', lineHeight: '115%', fontSize: '16px', fontFamily: '"Arial",sans-serif', textAlign: 'justify', textIndent: '-21.25pt'}}><strong><span style={{fontSize: '13px', lineHeight: '115%'}}>INVESTISSEMENT WOULIB INC.</span></strong></p>
					<p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '35.45pt', lineHeight: '115%', fontSize: '16px', fontFamily: '"Arial",sans-serif', textAlign: 'justify', textIndent: '-21.25pt'}}><span style={{fontSize: '13px', lineHeight: '115%'}}>PAR&nbsp;: DAVID THERRIAULT, PRÉSIDENT</span></p>
					<p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '35.45pt', lineHeight: '115%', fontSize: '16px', fontFamily: '"Arial",sans-serif', textAlign: 'justify', textIndent: '-21.25pt'}}><span style={{fontSize: '13px', lineHeight: '115%'}}>&nbsp;</span></p>
					<p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: '16px', fontFamily: '"Arial",sans-serif'}}><span style={{fontSize: '13px', lineHeight: '115%'}}>_________________________________________</span></p>
					<p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '35.45pt', lineHeight: '115%', fontSize: '16px', fontFamily: '"Arial",sans-serif', textAlign: 'justify', textIndent: '-21.25pt'}}><span style={{fontSize: '13px', lineHeight: '115%'}}>SOUS-TRAITANT MEMBRE</span></p>
					<p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '35.45pt', lineHeight: '115%', fontSize: '16px', fontFamily: '"Arial",sans-serif', textAlign: 'justify', textIndent: '-21.25pt'}}><span style={{fontSize: '13px', lineHeight: '115%'}}>PAR&nbsp;: {user.firstname.toUpperCase()} {user.lastname.toUpperCase()}</span><span style={{fontSize: '13px', lineHeight: '115%'}}>&nbsp;</span></p>
				</div>
				<Button
					type="submit"
					variant="contained"
					color="primary"
					className="w-full mx-auto mt-16"
					aria-label="J'AI LU ET J'APPROUVE CE CONTRAT"
					value="legacy"
				>
					J'AI LU ET J'APPROUVE CE CONTRAT
				</Button>
			</form>
		</div>
	);
}

export default JWTConfirmContractTab;
