import useSWR from 'swr';

// import userFetcher from 'utils/api/user';
const userFetcher = (url, token) => fetch(url, { headers: { Authorization: token }}).then((res) => res.json());


const urlApi = process.env.REACT_APP_API_URL;
export default function useUser() {

    const token = window.localStorage.getItem('jwt_access_token');

    const services =  `${urlApi}/api/v1.0/auth/user/me`;

    const { data, mutate, error } = useSWR([services, token], userFetcher);

    const loading = !data && !error;

    return {
        loading,
        error,
        data,
        // user: data,
        mutate,
    };
}
