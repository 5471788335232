import { lazy } from 'react';
import {authRoles} from "../../../../auth";

const CandidatePageConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth: authRoles.admin,
    routes: [
        {
            path: '/admin/candidate/add',
            component: lazy(() => import('./CandidateAdd'))
        },
        {
            path: '/admin/candidate/:id',
            component: lazy(() => import('./CandidateList'))
        },
        {
            path: '/admin/candidate/list',
            component: lazy(() => import('./CandidateList'))
        }
    ]
};

export default CandidatePageConfig;
