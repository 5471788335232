import { lazy } from 'react';
import {authRoles} from "../../../../auth";

const EmployeesAdminPageConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.admin,
	routes: [
		{
			path: '/admin/employees/:profileId',
			component: lazy(() => import('./EmployeesPage'))
		},
		{
			path: '/admin/employees',
			component: lazy(() => import('./EmployeesPage'))
		}
	]
};

export default EmployeesAdminPageConfig;
