import { lazy } from 'react';
import {authRoles} from "../../auth";

const MailConfirmPageConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: false
                },
                toolbar: {
                    display: false
                },
                footer: {
                    display: false
                },
                leftSidePanel: {
                    display: false
                },
                rightSidePanel: {
                    display: false
                }
            }
        }
    },
    auth: authRoles.onlyGuest,
    routes: [
        {
            path: '/mail-confirm',
            component: lazy(() => import('./MailConfirmPage'))
        }
    ]
};

export default MailConfirmPageConfig;
