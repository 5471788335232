import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import {useHistory} from "react-router";

const FaqToggleToggle = props => {
	function toggleFullScreen() {
		const win = window.open("https://jobetrouve.freshdesk.com/support/home", "_blank");
		win.focus();
	}

	return (
		<Tooltip title="Foire aux questions" placement="bottom">
			<IconButton onClick={toggleFullScreen} color="primary" className={clsx('w-64 h-40 text-13', props.className)}>
				<Icon>help_outline</Icon> FAQ
			</IconButton>
		</Tooltip>
	);
};

export default FaqToggleToggle;
