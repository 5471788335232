import { lazy } from 'react';
import {authRoles} from "../../../../auth";

const TrainingAdminPageConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth: authRoles.admin,
    routes: [
        {
            path: '/admin/training/add',
            component: lazy(() => import('./TrainingAdd'))
        },
        {
            path: '/admin/training/list',
            component: lazy(() => import('./TrainingList'))
        }
    ]
};

export default TrainingAdminPageConfig;
