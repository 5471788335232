import { lazy } from 'react';
import {authRoles} from "../../../../auth";

const EvaluationPageConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth: authRoles.admin,
    routes: [
        {
            path: '/admin/evaluation/language/:language/:evaluationId/:idjobetrouve',
            component: lazy(() => import('./LanguageEdit'))
        },
        {
            path: '/admin/evaluation/add',
            component: lazy(() => import('./EvaluationAdd'))
        },
        {
            path: '/admin/evaluationpro/add',
            component: lazy(() => import('./EvaluationproAdd'))
        },
        {
            path: '/admin/evaluation',
            component: lazy(() => import('./EvaluationList'))
        }
    ]
};

export default EvaluationPageConfig;
