import { authRoles } from 'app/auth';
import ChangePassword from './ChangePassword';

const ChangePasswordConfig = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: false
				},
				toolbar: {
					display: false
				},
				footer: {
					display: false
				},
				leftSidePanel: {
					display: false
				},
				rightSidePanel: {
					display: false
				}
			}
		}
	},
	auth: authRoles.user,
	routes: [
		{
			path: '/change-password',
			component: ChangePassword
		}
	]
};

export default ChangePasswordConfig;
