import { lazy } from 'react';
import {authRoles} from "../../../auth";

const OffersPageConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth: authRoles.user,
    routes: [
        {
            path: '/offer/:offerId',
            component: lazy(() => import('./OfferDetail'))
        },
        {
            path: '/offers',
            component: lazy(() => import('./Offers'))
        }
    ]
};

export default OffersPageConfig;
