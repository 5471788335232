import { lazy } from 'react';
import {authRoles} from "../../../../auth";

const TestsAdminPageConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth: authRoles.admin,
    routes: [
        {
            path: '/admin/test/add',
            component: lazy(() => import('./TestAdd'))
        },
        {
            path: '/admin/test/:id',
            component: lazy(() => import('./TestList'))
        },
        {
            path: '/admin/test/list',
            component: lazy(() => import('./TestList'))
        }
    ]
};

export default TestsAdminPageConfig;
