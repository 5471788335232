import FuseUtils from '@fuse/utils';
import authRoleExamplesConfigs from 'app/main/auth/authRoleExamplesConfigs';
import CallbackConfig from 'app/main/callback/CallbackConfig';
import LogoutConfig from 'app/main/logout/LogoutConfig';
import pagesConfigs from 'app/main/pages/pagesConfigs';
import UserInterfaceConfig from 'app/main/user-interface/UserInterfaceConfig';
import { Redirect } from 'react-router-dom';
import ChangePasswordConfig from "../main/change-password/ChangePasswordConfig";

const routeConfigs = [
	...pagesConfigs,
	...authRoleExamplesConfigs,
	UserInterfaceConfig,
	ChangePasswordConfig,
	LogoutConfig,
	CallbackConfig
];

const routes = [
	// if you want to make whole app auth protected by default change defaultAuth for example:
	// ...FuseUtils.generateRoutesFromConfigs(routeConfigs, ['admin','staff','user']),
	// The individual route configs which has auth option won't be overridden.
	...FuseUtils.generateRoutesFromConfigs(routeConfigs,  []),
	{
		path: '/',
		exact: true,
		component: () => <Redirect to="/profile" />
	},
	{
		component: () => <Redirect to="/error-404" />
	}
];

export default routes;
