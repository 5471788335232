import { lazy } from 'react';
import {authRoles} from "../../../auth";

const ProfilePageConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.user,
	routes: [
		{
			path: '/profile/training/new',
			component: lazy(() => import('./form/EducationForm'))
		},
		{
			path: '/profile/experience/new',
			component: lazy(() => import('./form/ExperienceForm'))
		},
		{
			path: '/profile/edit/:profileId',
			component: lazy(() => import('./form/ProfileForm'))
		},
		{
			path: '/profile',
			component: lazy(() => import('./ProfilePage'))
		},
		{
			path: '/profile/:profileId',
			component: lazy(() => import('./ProfilePage'))
		}
	]
};

export default ProfilePageConfig;
