import { lazy } from 'react';
import {authRoles} from "../../../../auth";

const OfferAdminPageConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth: authRoles.admin,
    routes: [
        {
            path: '/admin/offer/edit/:param',
            component: lazy(() => import('./OfferEdit'))
        },
        {
            path: '/admin/offer/add',
            component: lazy(() => import('./OfferAdd'))
        },
        {
            path: '/admin/offers',
            component: lazy(() => import('./OfferList'))
        }
    ]
};

export default OfferAdminPageConfig;
