import { lazy } from 'react';
import {authRoles} from "../../../../auth";

const ProfileAdminPageConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.admin,
	routes: [
		{
			path: '/admin/profile/training/:profileId/new',
			component: lazy(() => import('./form/EducationForm'))
		},
		{
			path: '/admin/profile/experience/:profileId/new',
			component: lazy(() => import('./form/ExperienceForm'))
		},
		{
			path: '/admin/profile/edit/:profileId',
			component: lazy(() => import('./form/ProfileForm'))
		},
		{
			path: '/admin/profile/:profileId',
			component: lazy(() => import('./ProfilePage'))
		}
	]
};

export default ProfileAdminPageConfig;
