import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { logoutUser } from 'app/auth/store/userSlice';
import clsx from "clsx";

function UserMenu(props) {
	const dispatch = useDispatch();
	const user = useSelector(({ auth }) => auth.user);

	const [userMenu, setUserMenu] = useState(null);

	const userMenuClick = event => {
		setUserMenu(event.currentTarget);
	};

	const userMenuClose = () => {
		setUserMenu(null);
	};

	return (
		<>
			<Button className="min-h-40 min-w-40 px-0 md:px-16 py-0 md:py-6" onClick={userMenuClick}>
				<div className="hidden md:flex flex-col mx-4 items-end">
					<Typography component="span" className="font-semibold flex">
						{user.firstname} {user.lastname} {user.completName}
					</Typography>
					<Typography className="text-11 font-medium capitalize">
						{(user.role)? (user.role === 'ROLE_APPLICANT')? (user.statusAccount && user.statusAccount.name === 'MEMBER')? 'Membre': 'Candidat': 'Employé' : 'd'}
						{(!user.role || (Array.isArray(user.role) && user.role.length === 0)) && 'Guest'}
					</Typography>
				</div>

				<Avatar
					className="md:mx-4" alt="user photo"
					src={
						user.imageUrl && user.imageUrl !== ''
							? user.imageUrl
							: 'assets/images/avatars/profile.jpg'
					}
				/>
			</Button>

			<Popover
				open={Boolean(userMenu)}
				anchorEl={userMenu}
				onClose={userMenuClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
				classes={{
					paper: 'py-8'
				}}
			>
				{user.role !== "ROLE_APPLICANT" ? (
					<>
						<MenuItem component={Link} to="/admin/employees" onClick={userMenuClose} role="button">
							<ListItemIcon className="min-w-40">
								<Icon>account_circle</Icon>
							</ListItemIcon>
							<ListItemText primary="Mon profil" />
						</MenuItem>
						<MenuItem component={Link} to="/change-password-profile" onClick={userMenuClose} role="button">
							<ListItemIcon className="min-w-40">
								<Icon>lock</Icon>
							</ListItemIcon>
							<ListItemText primary="Modifier mon mot de passe" />
						</MenuItem><MenuItem component={Link} to="/policy" onClick={userMenuClose} role="button">
							<ListItemIcon className="min-w-40">
								<Icon>privacy_tip</Icon>
							</ListItemIcon>
							<ListItemText primary="Politique de confidentialité" />
						</MenuItem>
						<MenuItem component={Link} to="/condition" onClick={userMenuClose} role="button">
							<ListItemIcon className="min-w-40">
								<Icon>gavel</Icon>
							</ListItemIcon>
							<ListItemText primary="Condition d'utilisation" />
						</MenuItem>
						<MenuItem
							onClick={() => {
								dispatch(logoutUser());
								userMenuClose();
							}}
						>
							<ListItemIcon className="min-w-40">
								<Icon>exit_to_app</Icon>
							</ListItemIcon>
							<ListItemText primary="Déconnexion" />
						</MenuItem>
					</>
				) : (
					<>
						<MenuItem component={Link} to="/subscription" onClick={userMenuClose} role="button">
							<ListItemIcon className="min-w-40">
								<Icon>assignment</Icon>
							</ListItemIcon>
							<ListItemText primary="Mon abonnement" />
						</MenuItem>
						<MenuItem component={Link} to="/contract" onClick={userMenuClose} role="button">
							<ListItemIcon className="min-w-40">
								<Icon>account_circle</Icon>
							</ListItemIcon>
							<ListItemText primary="Mon entente" />
						</MenuItem>
						<MenuItem component={Link} to="/invoice" onClick={userMenuClose} role="button">
							<ListItemIcon className="min-w-40">
								<Icon>receipt</Icon>
							</ListItemIcon>
							<ListItemText primary="Mes reçus" />
						</MenuItem>
						<MenuItem component={Link} to="/change-password-profile" onClick={userMenuClose} role="button">
							<ListItemIcon className="min-w-40">
								<Icon>lock</Icon>
							</ListItemIcon>
							<ListItemText primary="Modifier mon mot de passe" />
						</MenuItem>
						<MenuItem component={Link} to="/policy" onClick={userMenuClose} role="button">
							<ListItemIcon className="min-w-40">
								<Icon>privacy_tip</Icon>
							</ListItemIcon>
							<ListItemText primary="Politique de confidentialité" />
						</MenuItem>
						<MenuItem component={Link} to="/condition" onClick={userMenuClose} role="button">
							<ListItemIcon className="min-w-40">
								<Icon>gavel</Icon>
							</ListItemIcon>
							<ListItemText primary="Condition d'utilisation" />
						</MenuItem>
						<MenuItem
							onClick={() => {
								dispatch(logoutUser());
								userMenuClose();
							}}
						>
							<ListItemIcon className="min-w-40">
								<Icon>exit_to_app</Icon>
							</ListItemIcon>
							<ListItemText primary="Déconnexion" />
						</MenuItem>
					</>
				)}
			</Popover>
		</>
	);
}

export default UserMenu;
