import { lazy } from 'react';
import {authRoles} from "../../../../../auth";

const UserPageConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth: authRoles.superadmin,
    routes: [
        {
            path: '/admin/user/:param',
            component: lazy(() => import('./UserAdd'))
        },
        {
            path: '/admin/users',
            component: lazy(() => import('./UserList'))
        }
    ]
};

export default UserPageConfig;
