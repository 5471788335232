import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useState } from 'react';
import {Link, useParams} from "react-router-dom";
import JWTForgotPasswordTab from "./tabs/JWTForgotPasswordTab";
import JWTForgotPassword2Tab from "./tabs/JWTForgotPassword2Tab";

const useStyles = makeStyles(theme => ({
	root: {
		background: `linear-gradient(to left, ${theme.palette.primary.dark} 0%, ${darken(
			theme.palette.primary.dark,
			0.5
		)} 100%)`,
		color: theme.palette.primary.contrastText
	},
	leftSection: {},
	rightSection: {
		background: `linear-gradient(to left, ${theme.palette.primary.dark} 0%, ${darken(
			theme.palette.primary.dark,
			0.5
		)} 100%)`,
		color: theme.palette.primary.contrastText
	}
}));

function ForgotPassword() {
	const classes = useStyles();
	const [selectedTab, setSelectedTab] = useState(0);
	const routeParams = useParams();
	function handleTabChange(event, value) {
		setSelectedTab(value);
	}

	return (
		<div
			className={clsx(
				classes.root,
				'flex flex-col flex-auto items-center justify-center p-16 sm:p-42'
			)}
		>
			<div className="flex flex-col items-center justify-center w-full">
			<motion.div
				initial={{ opacity: 0, scale: 0.6 }} animate={{ opacity: 1, scale: 1 }}
			>
				<Card
					className="w-full max-w-584"
				>
					<CardContent className="flex flex-col items-center justify-center w-full py-44 max-w-360">
						<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.2 } }}>
							<div className="flex items-center mb-16">
								<img className="logo-icon" src="assets/images/logos/logo_jobetrouve.png" alt="logo" />
							</div>
						</motion.div>
						<Typography variant="h6" className="mt-16 mb-24 font-semibold text-18 sm:text-24">
							Mot de passe oublié
						</Typography>
						{ !routeParams.token && <Typography variant="h2" className="text-12 sm:text-14">
							Afin de récupérer votre mot de passe, vous aurez besoin de votre numéro de membre ou de votre email.
						</Typography> }
						<br/>
						{(routeParams.token)? <Typography variant="h2" className="text-12 sm:text-14">
							Entrer un nouveau mot de passe
						</Typography> : <Typography variant="h2" className="text-12 sm:text-14">
							Nous vous enverrons un email contenant votre nouveau mot de passe.
						</Typography> }
						<br/>
						{(routeParams.token)? <JWTForgotPassword2Tab token={routeParams.token} /> : <JWTForgotPasswordTab /> }

					</CardContent>

					<div className="flex flex-col items-center justify-center pb-32">
						<Link className="font-normal mt-8" to="/login">
							Retour à la page de connexion
						</Link>
					</div>
				</Card>
			</motion.div>
			</div>
		</div>
	);
}

export default ForgotPassword;
