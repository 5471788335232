import { lazy } from 'react';
import {authRoles} from "../../../../auth";

const Error404PageConfig = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: false
				},
				toolbar: {
					display: false
				},
				footer: {
					display: false
				},
				leftSidePanel: {
					display: false
				},
				rightSidePanel: {
					display: false
				}
			}
		}
	},
	auth: authRoles.user,
	routes: [
		{
			path: '/error-404',
			component: lazy(() => import('./Error404Page'))
		}
	]
};

export default Error404PageConfig;
