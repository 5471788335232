import { lazy } from 'react';
import {authRoles} from "../../auth";

const PaymentPageConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: false
                },
                toolbar: {
                    display: false
                },
                footer: {
                    display: false
                },
                leftSidePanel: {
                    display: false
                },
                rightSidePanel: {
                    display: false
                }
            }
        }
    },
    auth: authRoles.onlyGuest,
    routes: [
        {
            path: '/error-payement/:message',
            component: lazy(() => import('./PaymentErrorPage'))
        },
        {
            path: '/success-payement/:message',
            component: lazy(() => import('./PaymentSuccessPage'))
        }
        ,
        {
            path: '/error-payement',
            component: lazy(() => import('./PaymentErrorPage'))
        },
        {
            path: '/success-payement',
            component: lazy(() => import('./PaymentSuccessPage'))
        }
    ]
};

export default PaymentPageConfig;
