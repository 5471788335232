import { yupResolver } from '@hookform/resolvers/yup';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {changePasseword, resetPasswordConfirm, submitLogin} from 'app/auth/store/loginSlice';
import * as yup from 'yup';
import _ from '@lodash';
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {Link, useHistory} from "react-router-dom";
import {showMessage} from "../../../store/fuse/messageSlice";

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
	password: yup
		.string()
		.required('Veuillez entrer votre nouveau mot de passe')
		.matches(
			/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
			"Doit contenir 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial."
		),
	confirmPassword: yup
		.string()
		.required()
		.oneOf([yup.ref("password"), null], "Les nouveaux mots de passe doivent correspondre")

});

const defaultValues = {
	password: '',
	confirmPassword: ''
};

function JWTForgotPassword2Tab(props) {
	const dispatch = useDispatch();
	const history = useHistory();
	const { control, setValue, formState, handleSubmit, reset, trigger, setError } = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema)
	});

	const { isValid, dirtyFields, errors } = formState;

	const [showPassword, setShowPassword] = useState(false);
	const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

	function onSubmit(model) {
		const dataChange = {
			password: model.password,
			code: props.token
		}
		dispatch(resetPasswordConfirm(dataChange)).then(action => {
			if(!action.error){
				dispatch(showMessage({ message: 'Mot de passe changé avec succès', variant: 'success' }));
				history.push(`/login`);
			}
		});
	}

	return (
		<div className="w-full">
			<form className="flex flex-col justify-center w-full" onSubmit={handleSubmit(onSubmit)}>
				<Controller
					name="password"
					control={control}
					render={({ field }) => (
						<TextField
							{...field}
							className="mb-16"
							label="Nouveau mot de passe"
							type="password"
							error={!!errors.password}
							helperText={errors?.password?.message}
							variant="outlined"
							InputProps={{
								className: 'pr-2',
								type: showPassword ? 'text' : 'password',
								endAdornment: (
									<InputAdornment position="end">
										<IconButton onClick={() => setShowPassword(!showPassword)}>
											<Icon className="text-20" color="action">
												{showPassword ? 'visibility' : 'visibility_off'}
											</Icon>
										</IconButton>
									</InputAdornment>
								)
							}}
							required
						/>
					)}
				/>
				<Controller
					name="confirmPassword"
					control={control}
					render={({ field }) => (
						<TextField
							{...field}
							className="mb-16"
							label="Repéter le nouveau mot de passe"
							type="password"
							error={!!errors.confirmPassword}
							helperText={errors?.confirmPassword?.message}
							variant="outlined"
							InputProps={{
								className: 'pr-2',
								type: showPasswordConfirm ? 'text' : 'password',
								endAdornment: (
									<InputAdornment position="end">
										<IconButton onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}>
											<Icon className="text-20" color="action">
												{showPasswordConfirm ? 'visibility' : 'visibility_off'}
											</Icon>
										</IconButton>
									</InputAdornment>
								)
							}}
							required
						/>
					)}
				/>

				<Button
					type="submit"
					variant="contained"
					color="primary"
					className="w-full mx-auto mt-16"
					aria-label="Réinitialiser mon mot de passe"
					disabled={_.isEmpty(dirtyFields) || !isValid}
					value="legacy"
				>
					Réinitialiser mon mot de passe
				</Button>
			</form>
		</div>
	);
}

export default JWTForgotPassword2Tab;
