import { lazy } from 'react';
import {authRoles} from "../../../../../auth";

const ConfigurationPageConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth: authRoles.superadmin,
    routes: [
        {
            path: '/admin/configuration',
            component: lazy(() => import('./ConfigurationAdd'))
        }
    ]
};

export default ConfigurationPageConfig;
