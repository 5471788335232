/**
 * Authorization Roles
 */
const authRoles = {
    admin: ['ROLE_ADMIN', 'ROLE_SUPERADMIN', 'ROLE_DIRECTOR', 'ROLE_AGENT'],
    superadmin: ['ROLE_SUPERADMIN'],
    member: ['ROLE_APPLICANT'],
    candidate: ['ROLE_APPLICANT'],
    user: ['ROLE_ADMIN', 'ROLE_APPLICANT', 'ROLE_SUPERADMIN', 'ROLE_DIRECTOR', 'ROLE_AGENT'],
    onlyGuest: []
};

export default authRoles;