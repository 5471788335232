import { authRoles } from 'app/auth';
import i18next from 'i18next';

import fr from './navigation-i18n/fr';
import en from './navigation-i18n/en';
import es from './navigation-i18n/es';
import useUser from "../main/data/use-user";

i18next.addResourceBundle('fr', 'navigation', fr);
i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('es', 'navigation', es);

const NavigationConfig = () => {



    const navigationConfig = [{
        id: 'applications',
        title: 'Applications',
        translate: 'APPLICATIONS',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'profil',
                title: 'Profil',
                translate: 'Profil',
                type: 'item',
                auth: authRoles.member,
                icon: 'person',
                url: '/profile'
            },
            {
                id: 'offers',
                title: "Offres d'emploi",
                translate: 'OFFERS',
                auth: authRoles.member,
                type: 'item',
                icon: 'local_offer',
                url: '/offers'
            },
            {
                id: 'evaluations',
                title: "Évaluations",
                translate: 'Évaluations',
                auth: authRoles.member,
                type: 'item',
                icon: 'edit',
                url: '/tests'
            },
            {
                id: 'support',
                title: "Assistance",
                translate: 'Assistance',
                auth: authRoles.member,
                type: 'item',
                icon: 'help_outline',
                url: '/support'
            },
            // {
            //     id: 'training',
            //     title: "Formations",
            //     translate: 'Formations',
            //     auth: authRoles.member,
            //     type: 'item',
            //     icon: 'school',
            //     url: '/training'
            // },
            {
                id: 'dashboard',
                title: 'Tableau de bord',
                translate: 'DASHBOARDS',
                auth: authRoles.admin,
                type: 'item',
                icon: 'dashboard',
                url: '/admin/dashboard'
            },
            {
                id: 'candidate',
                title: 'Candidats',
                translate: 'CANDIDATE',
                auth: authRoles.admin,
                type: 'collapse',
                icon: 'people',
                children: [{
                    id: 'candidatelist',
                    title: 'List',
                    translate: 'LIST',
                    auth: authRoles.admin,
                    type: 'item',
                    url: '/admin/candidate/list'
                },
                    {
                        id: 'addcandidate',
                        title: 'Ajouter',
                        auth: authRoles.admin,
                        translate: 'ADD',
                        type: 'item',
                        url: '/admin/candidate/add'
                    }
                ]
            },
            {
                id: 'member',
                title: 'Membres',
                translate: 'MEMBER',
                auth: authRoles.admin,
                type: 'collapse',
                icon: 'people',
                children: [{
                    id: 'memberlist',
                    title: 'List',
                    translate: 'LIST',
                    auth: authRoles.admin,
                    type: 'item',
                    url: '/admin/member/list'
                },
                    // {
                    //     id: 'addmember',
                    //     title: 'Ajouter',
                    //     translate: 'ADD',
                    //     auth: authRoles.admin,
                    //     type: 'item',
                    //     url: '/admin/member/add'
                    // }
                ]
            },
            {
                id: 'company',
                title: 'Entreprises',
                translate: 'COMPANY',
                auth: authRoles.admin,
                type: 'collapse',
                icon: 'business',
                children: [{
                    id: 'companylist',
                    title: 'List',
                    translate: 'LIST',
                    auth: authRoles.admin,
                    type: 'item',
                    url: '/admin/company/list'
                },
                    {
                        id: 'addcompany',
                        title: 'Ajouter',
                        auth: authRoles.admin,
                        translate: 'ADD',
                        type: 'item',
                        url: '/admin/company/add'
                    }
                ]
            },
            {
                id: 'offer',
                title: 'Offres d\'emploi',
                translate: 'Offres d\'emploi',
                auth: authRoles.admin,
                type: 'collapse',
                icon: 'local_offer',
                children: [{
                    id: 'offerlist',
                    title: 'List',
                    translate: 'LIST',
                    auth: authRoles.admin,
                    type: 'item',
                    url: '/admin/offers'
                },
                    {
                        id: 'addoffer',
                        title: 'Ajouter',
                        auth: authRoles.admin,
                        translate: 'ADD',
                        type: 'item',
                        url: '/admin/offer/add'
                    }
                ]
            },
            // {
            //     id: 'Tests',
            //     title: 'Tests',
            //     translate: 'Tests',
            //     auth: authRoles.admin,
            //     type: 'collapse',
            //     icon: 'edit',
            //     children: [{
            //         id: 'testlist',
            //         title: 'List',
            //         translate: 'LIST',
            //         auth: authRoles.admin,
            //         type: 'item',
            //         url: '/admin/test/list'
            //     },
            //         {
            //             id: 'addtest',
            //             title: 'Ajouter',
            //             auth: authRoles.admin,
            //             translate: 'ADD',
            //             type: 'item',
            //             url: '/admin/test/add'
            //         }
            //     ]
            // },
            // {
            //     id: 'Training',
            //     title: 'Formations',
            //     translate: 'Formations',
            //     auth: authRoles.admin,
            //     type: 'collapse',
            //     icon: 'school',
            //     children: [{
            //         id: 'traininglist',
            //         title: 'List',
            //         translate: 'LIST',
            //         auth: authRoles.admin,
            //         type: 'item',
            //         url: '/admin/training/list'
            //     },
            //         {
            //             id: 'addtraining',
            //             title: 'Ajouter',
            //             auth: authRoles.admin,
            //             translate: 'ADD',
            //             type: 'item',
            //             url: '/admin/training/add'
            //         }
            //     ]
            // },
            {
                id: 'evaluate',
                title: 'Évaluations',
                translate: 'Évaluations',
                auth: authRoles.admin,
                type: 'item',
                icon: 'assignment_turned_in',
                url: '/admin/evaluation'
            },
            {
                id: 'setting',
                title: 'Paramètres',
                translate: 'Paramètres',
                auth: authRoles.superadmin,
                type: 'collapse',
                icon: 'settings',
                children: [{
                    id: 'users',
                    title: 'Utilisateurs',
                    translate: 'Utilisateurs',
                    auth: authRoles.superadmin,
                    type: 'item',
                    url: '/admin/users'
                },
                    {
                        id: 'adduser',
                        title: "Ajouter un utilisateur",
                        translate: 'Ajouter un utilisateur',
                        auth: authRoles.superadmin,
                        type: 'item',
                        url: '/admin/user/add'
                    },
                    {
                        id: 'configuration',
                        title: "Configuration",
                        translate: 'Configuration',
                        auth: authRoles.superadmin,
                        type: 'item',
                        url: '/admin/configuration'
                    }
                ]
            }
        ]
    },
    ];
    return navigationConfig;
}


const navigationConfig = [{
        id: 'applications',
        title: 'Applications',
        translate: 'APPLICATIONS',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'profil',
                title: 'Profil',
                translate: 'Profil',
                type: 'item',
                auth: authRoles.member,
                icon: 'person',
                url: '/profile'
            },
            {
                id: 'offers',
                title: "Offres d'emploi",
                translate: 'OFFERS',
                auth: authRoles.member,
                type: 'item',
                icon: 'local_offer',
                url: '/offers',
                // badge: {
                //     title: 13,
                //     bg: 'rgb(9, 210, 97)',
                //     fg: '#FFFFFF'
                // }
            },
            {
                id: 'evaluations',
                title: "Évaluations",
                translate: 'Évaluations',
                auth: authRoles.member,
                type: 'item',
                icon: 'edit',
                url: '/tests',
                // badge: {
                //     title: 13,
                //     bg: 'rgb(9, 210, 97)',
                //     fg: '#FFFFFF'
                // }
            },
            {
                id: 'support',
                title: "Support",
                translate: 'Support',
                auth: authRoles.member,
                type: 'item',
                icon: 'help_outline',
                url: '/support'
            },
            // {
            //     id: 'training',
            //     title: "Formations",
            //     translate: 'Formations',
            //     auth: authRoles.member,
            //     type: 'item',
            //     icon: 'school',
            //     url: '/training'
            // },
            {
                id: 'dashboard',
                title: 'Tableau de bord',
                translate: 'DASHBOARDS',
                auth: authRoles.admin,
                type: 'item',
                icon: 'dashboard',
                url: '/admin/dashboard'
            },
            {
                id: 'candidate',
                title: 'Candidats',
                translate: 'CANDIDATE',
                auth: authRoles.admin,
                type: 'collapse',
                icon: 'people',
                children: [{
                    id: 'candidatelist',
                    title: 'List',
                    translate: 'LIST',
                    auth: authRoles.admin,
                    type: 'item',
                    url: '/admin/candidate/list'
                },
                    {
                        id: 'addcandidate',
                        title: 'Ajouter',
                        auth: authRoles.admin,
                        translate: 'ADD',
                        type: 'item',
                        url: '/admin/candidate/add'
                    }
                ]
            },
            {
                id: 'member',
                title: 'Membres',
                translate: 'MEMBER',
                auth: authRoles.admin,
                type: 'collapse',
                icon: 'people',
                children: [{
                    id: 'memberlist',
                    title: 'List',
                    translate: 'LIST',
                    auth: authRoles.admin,
                    type: 'item',
                    url: '/admin/member/list'
                },
                    // {
                    //     id: 'addmember',
                    //     title: 'Ajouter',
                    //     translate: 'ADD',
                    //     auth: authRoles.admin,
                    //     type: 'item',
                    //     url: '/admin/member/add'
                    // }
                ]
            },
            {
                id: 'company',
                title: 'Entreprises',
                translate: 'COMPANY',
                auth: authRoles.admin,
                type: 'collapse',
                icon: 'business',
                children: [{
                    id: 'companylist',
                    title: 'List',
                    translate: 'LIST',
                    auth: authRoles.admin,
                    type: 'item',
                    url: '/admin/company/list'
                },
                    // {
                    //     id: 'addcompany',
                    //     title: 'Ajouter',
                    //     auth: authRoles.admin,
                    //     translate: 'ADD',
                    //     type: 'item',
                    //     url: '/admin/company/add'
                    // }
                ]
            },
            {
                id: 'offer',
                title: 'Offres d\'emploi',
                translate: 'Offres d\'emploi',
                auth: authRoles.admin,
                type: 'collapse',
                icon: 'local_offer',
                children: [{
                    id: 'offerlist',
                    title: 'List',
                    translate: 'LIST',
                    auth: authRoles.admin,
                    type: 'item',
                    url: '/admin/offers'
                },
                    {
                        id: 'addoffer',
                        title: 'Ajouter',
                        auth: authRoles.admin,
                        translate: 'ADD',
                        type: 'item',
                        url: '/admin/offer/add'
                    }
                ]
            },
            // {
            //     id: 'Tests',
            //     title: 'Tests',
            //     translate: 'Tests',
            //     auth: authRoles.admin,
            //     type: 'collapse',
            //     icon: 'edit',
            //     children: [{
            //         id: 'testlist',
            //         title: 'List',
            //         translate: 'LIST',
            //         auth: authRoles.admin,
            //         type: 'item',
            //         url: '/admin/test/list'
            //     },
            //         {
            //             id: 'addtest',
            //             title: 'Ajouter',
            //             auth: authRoles.admin,
            //             translate: 'ADD',
            //             type: 'item',
            //             url: '/admin/test/add'
            //         }
            //     ]
            // },
            // {
            //     id: 'Training',
            //     title: 'Formations',
            //     translate: 'Formations',
            //     auth: authRoles.admin,
            //     type: 'collapse',
            //     icon: 'school',
            //     children: [{
            //         id: 'traininglist',
            //         title: 'List',
            //         translate: 'LIST',
            //         auth: authRoles.admin,
            //         type: 'item',
            //         url: '/admin/training/list'
            //     },
            //         {
            //             id: 'addtraining',
            //             title: 'Ajouter',
            //             auth: authRoles.admin,
            //             translate: 'ADD',
            //             type: 'item',
            //             url: '/admin/training/add'
            //         }
            //     ]
            // },
            {
                id: 'evaluate',
                title: 'Évaluations',
                translate: 'Évaluations',
                auth: authRoles.admin,
                type: 'item',
                icon: 'assignment_turned_in',
                url: '/admin/evaluation'
            },
            {
                id: 'setting',
                title: 'Paramètres',
                translate: 'Paramètres',
                auth: authRoles.admin,
                type: 'collapse',
                icon: 'settings',
                children: [{
                    id: 'users',
                    title: 'Utilisateurs',
                    translate: 'Utilisateurs',
                    auth: authRoles.admin,
                    type: 'item',
                    url: '/admin/users'
                },
                    {
                        id: 'adduser',
                        title: "Ajouter un utilisateur",
                        translate: 'Ajouter un utilisateur',
                        auth: authRoles.admin,
                        type: 'item',
                        url: '/admin/user/add'
                    },
                    {
                        id: 'configuration',
                        title: "Configuration",
                        translate: 'Configuration',
                        auth: authRoles.admin,
                        type: 'item',
                        url: '/admin/configuration'
                    }
                ]
            }
        ]
    },
];

export default NavigationConfig;